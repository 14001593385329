const dropdownOptionsRaw = [
  "cc_lat",
  "cc_larh",
  "cc_eat",
  "cc_earh",
  "sf_amp",
  "air_vel",
  "chw_st",
  "chw_rt",
  "oa_temp",
  "oa_humidity",
  "oa_pressure",
  "hc_eat",
  "hc_lat",
  "hw_st",
  "hw_rt",
  "gc_eat",
  "gc_lat",
  "gw_st",
  "gw_rt",
  "cc_lat1",
  "cc_larh1",
  "cc_eat1",
  "cc_earh1",
  "cc_lat_avg",
  "cc_eat_avg",
  "sf_amp1",
  "pfilters_pd",
  "ffilters_pd",
  "chw_flow",
  "all",
];
const dropdownOptionsBio = [
  "temperature_supply",
  "humidity_supply",
  "temperature_return",
  "humidity_return",
  "current",
  "air_velocity",
  "flow_water_in_temp",
  "flow_water_out_temp",
  "oa_temp",
  "oa_humidity",
  "oa_pressure",
  "all",
];
const dropdownOptionsBioKey = {
  cc_lat: "temperature_supply",
  cc_larh: "humidity_supply",
  cc_eat: "temperature_return",
  cc_earh: "humidity_return",
  sf_amp: "current",
  air_vel: "air_velocity",
  chw_st: "flow_water_in_temp",
  chw_rt: "flow_water_out_temp",
  oa_temp: "oa_temp",
  oa_humidity: "oa_humidity",
  oa_pressure: "oa_pressure",

  temperature_supply: "cc_lat",
  humidity_supply: "cc_larh",
  temperature_return: "cc_eat",
  humidity_return: "cc_earh",
  current: "sf_amp",
  air_velocity: "air_vel",
  flow_water_in_temp: "chw_st",
  flow_water_out_temp: "chw_rt",
};

const dropdownOptionsFoul = [
  "cc_flvl",
  "hc_flvl",
  "gc_flvl",
  "all",
]

const dropdownOptionsFoulBio = [
  "ahu_flvl",
]

const dropdownOptionsFoulBioKey = {
  cc_flvl: "ahu_flvl",
  hc_flvl: "",
  gc_flvl: "",
};

const dropdownOptionsPerGen1 = [
  "btu",
  "sf_kwh",
  "cc_kwh",
  "cc_pump_kwh",
  "chw_dt",
  "cc_waterbtus",
  "all",
];

const dropdownOptionsPerBio = [
  "btu",
  "sf_kwh",
  "flow_temp_diff",
  "flow_energy",
  "all",
];

const dropdownOptionsPreBioKey = {
  btu: "btu",
  sf_kwh: "sf_kwh",
  cc_kwh: "",
  cc_pump_kwh: "",
  chw_dt: "flow_temp_diff",
  flow_energy: "flow_energy",
  flow_temp_diff: "chw_dt",
};

export {
  dropdownOptionsBio,
  dropdownOptionsRaw,
  dropdownOptionsPerGen1,
  dropdownOptionsPerBio,
  dropdownOptionsBioKey,
  dropdownOptionsPreBioKey,
  dropdownOptionsFoul,
  dropdownOptionsFoulBio,
  dropdownOptionsFoulBioKey,
};
