import fetchData from "./fetchData";
import {customer, devices, relation, relations, telemetry} from "./serverUrl";

export const getSelectedSharedAttributes = (deviceId) => {
    return fetchData(
        "GET",
        telemetry +
        "/" +
        deviceId.entityType +
        "/" +
        deviceId.id +
        "/values/attributes?keys=flvl,smartcoil_state,coil_type",
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {}
    ).then(response => {
        if (response?.length === 0) {
            return {};
        }
        return response.reduce((result, attribute) => {
            result[attribute.key] = attribute.value;
            return result;
        }, {});
    })
}

export async function createDevice(data) {
    return fetchData(
        "POST",
        `${devices}`,
        {
            "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        data
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response;
    });
}

export async function getSharedAttributes(deviceId) {
    return fetchData(
        "GET",
        `${telemetry}/${deviceId.entityType}/${deviceId.id}/values/attributes/SHARED_SCOPE`,
        {
            "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response;
    });
}

export async function saveSharedAttributes(deviceId, attributes) {
    return Promise.all(attributes.map(attribute => {
        const requestData = {
            [attribute.key]: attribute.value,
        }
        return fetchData(
            "POST",
            `${telemetry}/${deviceId.entityType}/${deviceId.id}/attributes/SHARED_SCOPE`,
            {
                "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
            requestData
        ).then((response) => {
            if (response.status) {
                throw response;
            }
            return response;
        });
    }));
}

export async function getDeviceEventsInLastThreeYears(deviceId) {
    const eventsSince = new Date();
    eventsSince.setFullYear(eventsSince.getFullYear() - 3)
    return fetchData(
        "GET",
        telemetry +
        "/" +
        deviceId?.entityType +
        "/" +
        deviceId?.id +
        "/values/timeseries?keys=ahu_event" +
        "&startTs=" + eventsSince.getTime() +
        "&endTs=" + new Date().getTime() +
        "&limit=10&orderBy=DESC",
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {}
    ).then((response) => {
        return response.ahu_event
    });
}

export async function addEventToDevice(deviceId, events) {
    return fetchData(
        "POST",
        telemetry +
        "/" +
        deviceId?.entityType +
        "/" +
        deviceId?.id +
        "/timeseries/ANY",
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        events
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response
    });
}

export async function assignCustomer(deviceId, customerId) {
    return fetchData(
        "POST",
        customer +
        "/" +
        customerId +
        "/device/" +
        deviceId?.id,
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {}
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response
    });
}

export async function unassignCustomer(deviceId) {
    return fetchData(
        "DELETE",
        customer +
        "/device/" +
        deviceId?.id,
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {}
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response
    });
}

export async function assignToBuilding(deviceId, buildingId) {
    return fetchData(
        "POST",
        relation,
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {
            "from": {
                "entityType": "ASSET",
                "id": buildingId
            },
            "to": {
                "entityType": "DEVICE",
                "id": deviceId.id
            },
            "type": "Contains",
            "typeGroup": "COMMON",
            "additionalInfo": null
        }
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response;
    });
}

export async function findAssignedBuilding(deviceId) {
    return fetchData(
        "GET",
        relations +
        `?toId=${deviceId.id}&toType=DEVICE&relationType=Contains`,
        { "X-Authorization": "Bearer " + localStorage.getItem("token") }
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response[0]?.from?.id || null;
    });
}

export async function unassignBuilding(deviceId, buildingId) {
    return fetchData(
        "DELETE",
        relation +
        `?toId=${deviceId.id}&toType=DEVICE&fromId=${buildingId}&fromType=ASSET&relationType=Contains`,
        { "X-Authorization": "Bearer " + localStorage.getItem("token") }
    ).then((response) => {
        if (response.status) {
            throw response;
        }
        return response;
    });
}
