import React, {useEffect, useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import {
    addEventToDevice, assignCustomer, assignToBuilding, createDevice, findAssignedBuilding,
    getDeviceEventsInLastThreeYears,
    getSelectedSharedAttributes,
    saveSharedAttributes, unassignBuilding, unassignCustomer
} from "../../../../axios/deviceApi";
import moment from "moment/moment";
import {Modal, Form, DatePicker, InputPicker, Stack, Schema} from "rsuite";
import {getCustomerBuildings, getCustomers} from "../../../../axios/customerApi";

const SmartCoilEditForm = (props) => {
    const initialState = {
        smartcoil_state: 0,
        coil_type: 'cooling',
        label: null,
        flvl: -1,
        selectedEvent: 'Coil_Cleaned',
        selectedEventTime: new Date(),
        customerId: null,
        buildingId: null
    };

    const labelRule = Schema.Types.StringType().isRequired('This field is required.');

    const [formValue, setFormValue] = useState(initialState);
    const [events, setEvents] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [buildings, setBuildings] = useState([]);

    useEffect(() => {
        if (!props.smartCoilGateway) {
            return;
        }
        if (props.smartCoilGateway.customerId?.id && props.smartCoilGateway.customerId?.id !== "13814000-1dd2-11b2-8080-808080808080") {
            getCustomerBuildings(props.smartCoilGateway.customerId?.id).then((buildings) => {
                const buildingMap = buildings.data.map((building) => ({value: building.id.id, label: building.name}));
                setBuildings(buildingMap);
            }).catch((error) => {
                console.error(error);
            });
        }
        setFormValue((formValue) => ({
            ...formValue,
            customerId: props.smartCoilGateway.customerId?.id,
            label: props.smartCoilGateway?.label,
        }));
        getSelectedSharedAttributes(props.smartCoilGateway.id).then((attributes) => {
            setFormValue((formValue) => ({...formValue, ...attributes}));
        });
        getCustomers().then((customers) => {
            const customerMap = customers.data.map((customer) => ({value: customer.id.id, label: customer.title}));
            setCustomers(customerMap);
        });
        findAssignedBuilding(props.smartCoilGateway.id).then(assignedBuilding => {
            setFormValue((formValue) => ({...formValue, buildingId: assignedBuilding}));
        });
        getDeviceEventsInLastThreeYears(props.smartCoilGateway.id).then((data) => {
            if (data) {
                setEvents(data)
            }
        });
    }, [props.smartCoilGateway]);

    const handleSmartCoilChange = (newValue) => {
        let flvl;
        switch (newValue) {
            case 2: flvl = 0; break;
            case 3: flvl = 1; break;
            default: flvl = -1;
        }
        setFormValue({ ...formValue, "smartcoil_state": newValue, flvl: flvl });
        saveSharedAttributes(props.smartCoilGateway.id, [
            {key: 'smartcoil_state', value: newValue},
            {key: 'flvl', value: flvl}
        ]);
    };

    const handleCoilTypeChange = (newValue) => {
        setFormValue({ ...formValue, "coil_type": newValue});
        saveSharedAttributes(props.smartCoilGateway.id, [
            {key: 'coil_type', value: newValue},
        ]);
    };

    function resetState() {
        setFormValue(initialState);
        setEvents([]);
        setCustomers([]);
        setBuildings([]);
    }

    const handleCancel = (e) => {
        resetState();
        props.onClose()
    };

    const handleFinish = () => {
        console.log(!formValue.label);
        if (!formValue.label) {
            toast.warning("Check form for validation errors.");
            return;
        }
        if (props.smartCoilGateway.label !== formValue.label) {
            createDevice({
                id: props.smartCoilGateway.id,
                name: props.smartCoilGateway.name,
                label: formValue.label
            }).then(() => {
                toast.success("Label saved");
                resetState();
                props.onSubmit();
            }).catch((error) => {
                toast.error("Failed to save label: " + error.message);
            });
        } else {
            resetState();
            props.onSubmit();
        }
    }

    function handleAddEvent() {
        const { selectedEvent, selectedEventTime } = formValue;
        const eventsToSave = {"ts":selectedEventTime.getTime(), "values":{"ahu_event": selectedEvent}};
        addEventToDevice(props.smartCoilGateway.id, eventsToSave).then(() => {
            setEvents([{ts: selectedEventTime.getTime(), value: selectedEvent}, ...events]);
            toast.success("Event added!");
        }).catch((err) => {
            toast.error("Failed to add event: " + err.message);
        });
    }

    const handleSelectCustomer = (newCustomerId) => {
        if (newCustomerId === null) {
            unassignCustomer(props.smartCoilGateway.id).then(() => {
                toast.success("Customer unassigned!");
                setBuildings([]);
                if (formValue.buildingId) {
                    console.log(formValue.buildingId);
                    unassignBuilding(props.smartCoilGateway.id, formValue.buildingId)
                        .then(() => {
                                toast.success("Building unassigned!");
                        })
                        .catch((err) => {
                            toast.error("Failed to remove previously assigned building: " + err.message);
                        });
                }
            }).catch((err) => {
                toast.error("Failed to unassign customer: " + err.message);
            })
        } else {
            assignCustomer(props.smartCoilGateway.id, newCustomerId).then(() => {
                toast.success("Customer assigned!");
                getCustomerBuildings(newCustomerId).then((buildings) => {
                    const buildingMap = buildings.data.map((building) => ({value: building.id.id, label: building.name}));
                    setBuildings(buildingMap);
                });
            }).catch((err) => {
                toast.error("Failed to assign customer: " + err.message);
            });
        }
    }

    const handleSelectBuilding = (newBuildingId) => {
        setFormValue((formValue) => ({...formValue, buildingId: newBuildingId}));
        const previousBuildingId = formValue.buildingId;
        if (previousBuildingId) {
            unassignBuilding(props.smartCoilGateway.id, previousBuildingId)
                .then(() => {
                    if (newBuildingId === null) {
                        toast.success("Building unassigned!");
                    }
                })
                .catch((err) => {
                    toast.error("Failed to remove previously assigned building: " + err.message);
            });
        }
        if (newBuildingId !== null) {
            assignToBuilding(props.smartCoilGateway.id, newBuildingId).then(() => {
                toast.success("Building assigned!");
            }).catch((err) => {
                toast.error("Failed to assign building: " + err.message);
            });
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal open={props.show} onClose={handleCancel} size="md">
                <Modal.Header closeButton><span className="admin-subtitle">Edit device</span></Modal.Header>
                <Modal.Body>
                    <Form fluid
                          formValue={formValue}
                          onChange={formValue => setFormValue(formValue)}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.ControlLabel controlId="name">Name</Form.ControlLabel>
                            <Form.Control
                                type="text"
                                name="name"
                                value={props.smartCoilGateway?.name}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="label">
                            <Form.ControlLabel controlId="label">Label</Form.ControlLabel>
                            <Form.Control
                                type="text"
                                name="label"
                                rule={labelRule}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="coil_type">
                            <Form.ControlLabel controlId="coil_type">Coil state</Form.ControlLabel>
                            <Form.Control name="coil_type" accepter={InputPicker} onChange={handleCoilTypeChange} data={[
                                {label: "Cooling", value: "cooling"},
                                {label: "Heating", value: "heating"},
                                {label: "HRC", value: "HRC"},
                                {label: "Dual Temp", value: "dual_temp"}
                            ]} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="smartcoil_state">
                            <Form.ControlLabel controlId="smartcoil_state">Smart coil state</Form.ControlLabel>
                            <Form.Control name="smartcoil_state" accepter={InputPicker} onChange={handleSmartCoilChange} data={[
                                {label: "Provisioned", value: 0},
                                {label: "Baselining", value: 1},
                                {label: "Optimizing", value: 2},
                                {label: "Monitoring", value: 3}
                            ]} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="flvl">
                            <Form.ControlLabel controlId="flvl">Fouling level</Form.ControlLabel>
                            <Form.Control
                                name="flvl"
                                value={formValue.flvl}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="selectedEvent">
                            <Form.ControlLabel controlId="selectedEvent">Event</Form.ControlLabel>
                            <Stack horizontal spacing={6}>
                                <Form.Control name="selectedEvent" accepter={InputPicker}
                                              data={[
                                                  {label: "Coil Cleaned", value: "Coil_Cleaned"},
                                                  {label: "SmartCoil Installed", value: "SmartCoil_Installed"}
                                              ]}/>
                                <Form.Control name="selectedEventTime"
                                              format="MM/dd/yyyy hh:mm aa"
                                              showMeridiem
                                              placeholder="Time of event"
                                              accepter={DatePicker} />

                                <button className="btn btn-outline-success" onClick={handleAddEvent}>Add event</button>
                            </Stack>
                        </Form.Group>
                        <div className="event-tracker mb-3">
                        <table style={{maxWidth: "unset"}}>
                                <tbody>
                                {events?.map((item, index) => {
                                    if (index <= 1 && item?.value !== null) {
                                        return (
                                            <tr key={item?.value + index}>
                                                <td style={{minWidth: '100px'}}>{item?.value?.replaceAll('_', ' ')}</td>
                                                <td>
                                                    {moment(new Date(parseInt(item?.ts))).format(
                                                        "MMMM DD, YYYY"
                                                    )}
                                                </td>
                                                <td>{moment(item?.ts).format("hh:mm A")}</td>
                                            </tr>
                                        );
                                    }
                                    return <></>
                                })}
                                </tbody>
                            </table>
                        </div>
                        <Form.Group className="mb-3" controlId="customerId">
                            <Form.ControlLabel controlId="customerId">Customer</Form.ControlLabel>
                            <Form.Control name="customerId" accepter={InputPicker} onChange={handleSelectCustomer} data={customers} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="buildingId">
                            <Form.ControlLabel controlId="buildingId">Building</Form.ControlLabel>
                            <Form.Control name="buildingId" accepter={InputPicker} onChange={handleSelectBuilding} data={buildings} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-success mr-3" onClick={handleFinish}>Finish</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SmartCoilEditForm;
