import { useState } from "react";
import CustomLoader from "../../../../component/Loader/Loader";
import fetchData from "../../../../axios/fetchData";
import { chnagePassword } from "../../../../axios/serverUrl";
const PasswordInformation = ({ userDetail }) => {
  const [isLoading, setLoading] = useState(false);

  const [userPassword, setUserPassword] = useState({
    currentPassword: null,
    newPassword: null,
    confirmNewPassword: null,
  });
  const [passwordErr, setPaawordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const checkPasswordValidation = (password, username) => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (!lowercaseRegex.test(password)) {
      return ["Password should contain lower case", false];
    }
    if (!uppercaseRegex.test(password)) {
      return ["Password should contain uppercase", false];
    }
    if (!numberRegex.test(password)) {
      return ["Password should contain number", false];
    }
    if (!specialCharRegex.test(password)) {
      return ["Password should contain Special character", false];
    }
    if (password.toLowerCase().includes(username.toLowerCase())) {
      return ["Password should not contain username", false];
    }
    if (password.length < 12) {
      return ["Length is not matched", false];
    }
    return ["all good", true];
  };

  const onSubmitInformation = async (e) => {
    e.preventDefault();
    // generate system admin token;
    setLoading(true);
    if (userPassword.newPassword === userPassword.confirmNewPassword) {
      if (
        userPassword.currentPassword !== null &&
        userPassword.newPassword !== null &&
        userPassword.confirmNewPassword !== null
      ) {
        let [err, valid] = checkPasswordValidation(
          userPassword.newPassword,
          userDetail?.name
        );
        if (valid) {
          const updatedPassword = await fetchData(
            "POST",
            chnagePassword,
            {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
            {
              currentPassword: userPassword.currentPassword,
              newPassword: userPassword.newPassword,
            }
          );
          if (updatedPassword?.errorCode === 11) {
            setLoading(false);
            navigator("/login");
            localStorage.clear();
          }
          if (updatedPassword?.status === 400) {
            alert(updatedPassword?.message);
            setLoading(false);
          } else if (updatedPassword?.token) {
            localStorage.setItem("token", updatedPassword.token);
            localStorage.setItem("refreshToken", updatedPassword.refreshToken);
            userPassword.currentPassword = "";
            userPassword.newPassword = "";
            userPassword.confirmNewPassword = "";

            setLoading(false);
          }
        } else {
          setLoading(false);
          alert(err);
        }
      } else {
        setLoading(false);
        alert("All Fields are required");
      }
    } else {
      setLoading(false);
      setConfirmPasswordErr("New Password should match with confirm Password");
    }
  };

  return (
    <form onSubmit={onSubmitInformation}>
      <input
        className="form-control-normal my-2"
        placeholder="Enter Current Password"
        type="password"
        value={userPassword.currentPassword}
        onChange={(e) =>
          setUserPassword({
            ...userPassword,
            currentPassword: e.target.value,
          })
        }
        required
      />
      <label className="admin-subtitle-text mt-2">New Password</label>
      <span style={{ color: "red", marginLeft: "10px" }}>{passwordErr}</span>
      <input
        className="form-control-normal my-2"
        placeholder="Enter New Password"
        type="password"
        value={userPassword.newPassword}
        onChange={(e) => {
          let [err, valid] = checkPasswordValidation(
            e.target.value,
            userDetail?.name
          );
          !valid ? setPaawordErr(err) : setPaawordErr("");
          setUserPassword({
            ...userPassword,
            newPassword: e.target.value,
          });
        }}
        required
      />
      <div className="d-flex justify-content-between password-type">
        <ul>
          <li>One lowercase character</li>
          <li className="my-2">One uppercase character</li>
          <li>One number</li>
        </ul>
        <ul className="">
          <li>One special character</li>
          <li className="my-2">12 characters minimum</li>
          <li>Must not contain username</li>
        </ul>
      </div>
      <span style={{ color: "red" }}>{confirmPasswordErr}</span>
      <input
        className="form-control-normal"
        placeholder="Confirm New Password"
        type="password"
        value={userPassword.confirmNewPassword}
        onChange={(e) => {
          setConfirmPasswordErr("");
          setUserPassword({
            ...userPassword,
            confirmNewPassword: e.target.value,
          });
        }}
        required
      />
      <div className="d-flex justify-content-end my-4">
        <button type="submit" className="btn-update">
          Update
        </button>
      </div>
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </form>
  );
};

export default PasswordInformation;
