import RenderChart from "../../../component/RenderData/RenderChart";
import lineChartDataOption from "./LineChartData";
import {usePageTracking} from "../../../analytics/analytics";
import {
  dropdownOptionsFoul,
  dropdownOptionsFoulBio,
  dropdownOptionsFoulBioKey,
} from "../../../utils/dataPoints";

function FounlingMetrics() {
  usePageTracking();
  return (
    <RenderChart
      chartOptions={lineChartDataOption}
      chartTitle="Fouling Metrics"
      dropdownOptions={dropdownOptionsFoul}
      dropdownOptionsBio={dropdownOptionsFoulBio}
      dropdownOptionsBioKey={dropdownOptionsFoulBioKey}
    />
  );
}
export default FounlingMetrics;
