import React, { useEffect, useState } from "react";
import AdminConsoleDataTable from "../AdminConsoleDataTable";
import CustomLoader from "../../../../component/Loader/Loader";
import Modal from "react-modal";
import {
  register,
  customer,
  activateAccount,
  getUser,
} from "../../../../axios/serverUrl";
import fetchData from "../../../../axios/fetchData";
import emailjs from "emailjs-com";
import {
  formattedDate,
  formattedDateString,
} from "../../../../axios/formattedDate";
import { ToastContainer, toast } from "react-toastify";
Modal.setAppElement("#root");

const Customers = ({
  userTableJson,
  CurrentCustomer,
  isModalIsOpen,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(isModalIsOpen);
  const token = localStorage.getItem("token");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userTableDataJson, setUserTableDataJson] = useState(userTableJson);

  useEffect(() => {
    setModalIsOpen(true);
    // eslint-disable-next-line
  }, [!isModalIsOpen]);

  useEffect(() => {
    setModalIsOpen(false);
    // eslint-disable-next-line
  }, []);
  const closeModal = () => {
    setModalIsOpen(false);
  };
  // const renderButton = (label, onClick) => (
  //   <button
  //     type="button"
  //     className="btn btn-outline-secondary btn-sm new-user-btn"
  //     onClick={onClick}
  //   >
  //     {label}
  //   </button>
  // );
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const generatePassword = () => {
    const lower = "abcdefghijklmnopqrstuvwxyz";
    const upper = lower.toUpperCase();
    const numbers = "0123456789";
    const specials = "!@#$%^&*()_+[]{}<>?/|";
    const allChars = lower + upper + numbers + specials;

    // Push required characters into password array.
    let password = [
        lower[Math.floor(Math.random() * lower.length)],
        upper[Math.floor(Math.random() * upper.length)],
        numbers[Math.floor(Math.random() * numbers.length)],
        specials[Math.floor(Math.random() * specials.length)]
    ];

    // Fill the remaining length with random characters
    for (let i = password.length; i < 12; i++) {
        password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }

    // Shuffle the password
    return password.sort(() => Math.random() - 0.5).join('');
  }

  const createNewUser = async (e) => {
    if (firstName !== "" && lastName !== "") {
      e.preventDefault();
      // Extract user details
      const newUserData = {
        email,
        firstName,
        lastName,
        authority: "CUSTOMER_USER",
        entityType: "CUSTOMER",
        tenantId: JSON.parse(localStorage.getItem("userDetail")).tenantId,
        customerId: {
          entityType: "CUSTOMER",
          id: CurrentCustomer,
        },
      };
      // Create new user
      setLoading(true);
      // /close modal
      closeModal();
      const response = await fetchData(
        "POST",
        register,
        { "X-Authorization": `Bearer ${token}` },
        newUserData
      );

      if (response?.id) {
        // set new user to user table
        const users = await fetchData(
          "GET",
          `${customer}/${
            response?.customerId.id
          }/users?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
          {
            "X-Authorization": `Bearer ${token}`,
          }
        );
        if (users?.errorCode === 11) {
          setLoading(false);
          navigator("/login");
          localStorage.clear();
        }
        const userData = users?.data?.map((userData) => ({
          image: (
            <div className="circle-highlight mr-2">{userData.name[0]}</div>
          ),
          imageText: userData.email,
          column1data: <div>{userData.firstName}</div>,
          column2data: <div>{userData.lastName}</div>,
          column3data: userData.email,
          column4data: (
            <div
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              style={{ cursor: "pointer" }}
              title={formattedDate(userData.createdTime)}
            >
              {formattedDateString(userData.createdTime)}
            </div>
          ),
        }));
        setUserTableDataJson(userData);
        setLoading(false);

        const password = generatePassword();
        // Generate activation link
        let name = response?.firstName ? response?.name : "";
        const activationLinkResponse = await fetchData(
          "GET",
          `${getUser}/${response.id.id}/activationLink`,
          { "X-Authorization": `Bearer ${token}` }
        );
        if (activationLinkResponse?.errorCode === 11) {
          setLoading(false);
          navigator("/login");
          localStorage.clear();
        }
        const activationLinkParams = new URL(activationLinkResponse);
        const activateToken =
          activationLinkParams.searchParams.get("activateToken");

        // Generate default password using activation token
        const setTokenAndDefaultPassword = {
          activateToken,
          password: password,
        };
        const setDefaultPassword = await fetchData(
          "POST",
          activateAccount,
          { "X-Authorization": `Bearer ${token}` },
          setTokenAndDefaultPassword
        );
        if (setDefaultPassword?.errorCode === 11) {
          setLoading(false);
          navigator("/login");
          localStorage.clear();
        }
        // Send email if password successfully generated
        if (setDefaultPassword?.token) {
          await emailjs
            .send(
              "service_vfofqtm",
              "template_xd2lxgj",
              {
                email: response.email,
                to_name: name,
                firstName: firstName,
                lastName: lastName,
                message:
                  window.location.origin +
                  "/reset-password?nameToken=" +
                  btoa(response.name),
                from_name: "Things Board",
                password: password,
              },
              "Rlk8panFMyjDbj0k9"
            )
            .catch((error) => {
              alert("Email sending failed:", error);
            });
        }
      } else if (response?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      } else if (response?.errorCode === 31) {
        alert(response.message);
      }
      setLoading(false);
    } else {
      e.preventDefault();
      toast.error("Please Enter mandatory fields.");
    }
  };

  return (
    <>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <h6 className="admin-subtitle">Add New User</h6>
          <form onSubmit={createNewUser}>
            <input
              className="form-control-normal mb-3"
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              className="form-control-normal mb-3"
              placeholder="First name *"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              className="form-control-normal mb-3"
              placeholder="Last name *"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-outline-danger mr-3"
              onClick={closeModal}
            >
              Cancel{" "}
            </button>
            <button type="submit" className="btn btn-outline-success">
              Add User
            </button>
          </form>
        </Modal>
        <div className="select-wrapper d-block d-md-flex mb-3 mb-md-0"></div>
        <AdminConsoleDataTable
          tableHeadings={[
            " ",
            "FirstName",
            "LastName",
            "Email",
            "Created time",
          ]}
          tableJson={userTableDataJson}
        />
        <CustomLoader
          idLoading={isLoading}
          handleLoading={() => setLoading(!isLoading)}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default Customers;
